<template>
  <div
    style="
      display: flex;
      min-height: calc(100vh - 160px);
      max-height: calc(100vh - 160px);
    "
    :style="{
      flexDirection: direction,
      paddingRight: direction == 'row' ? '200px' : '0',
    }"
  >
    <div
      style="line-height: 28px; flex: 1; padding: 20px; overflow-y: scroll"
      :style="{ borderRight: direction == 'row' ? '2px dashed #888' : 'none' }"
      :id="'container' + direction"
    >
      <div style="display: flex; flex-grow: 2px; margin-top: 30px;" id="policyNav">
        <div
          style="
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <el-button
            :size="direction == 'row' ? 'default' : 'small'"
            type="primary"
            :icon="ArrowLeft"
            round
            class="nav"
            >上一节</el-button
          >
        </div>

        <div
          style="
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <el-button
            :size="direction == 'row' ? 'default' : 'small'"
            type="success"
            :icon="Stamp"
            round
            class="nav"
            >完成</el-button
          >
        </div>

        <div
          style="
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <el-button
            :size="direction == 'row' ? 'default' : 'small'"
            type="warning"
            :icon="Star"
            round
            class="nav"
            >收藏</el-button
          >
        </div>

        <div
          style="
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <el-button
            :size="direction == 'row' ? 'default' : 'small'"
            type="primary"
            :icon="ArrowRight"
            round
            class="nav"
            >下一节</el-button
          >
        </div>
      </div>
    </div>

    <div
      style="flex: 1; padding: 20px; overflow-y: scroll; position: relative"
      :style="{
        borderRight: direction == 'row' ? '2px dashed #888' : 'none',
        borderTop: direction == 'column' ? '2px dashed #888' : 'none',
      }"
      v-if="showAnother"
    >
      <el-button
        type="danger"
        :icon="Close"
        circle
        style="position: absolute; top: 10px; right: 10px"
        @click="showAnother = false"
      />
      <div style="text-align: center; font-size: 24px; margin-bottom: 10px">
        {{ header }}
      </div>
      <div id="anotherContainer"></div>
    </div>
  </div>
</template>

<script>
import Http from "@/Http";
import {
  ArrowLeft,
  ArrowRight,
  Close,
  Stamp,
  Star,
} from "@element-plus/icons-vue";
export default {
  data() {
    return {
      header: "",
      showAnother: false,
      Close,
      ArrowLeft,
      ArrowRight,
      Stamp,
      Star,
    };
  },
  props: {
    direction: {
      type: String,
      default: "row",
    },
  },
  methods: {
    minArr(arr) {
      var min = 99999;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i] < min && arr[i] != -1) {
          min = arr[i];
        }
      }
      return min;
    },
    /**
     *
     * @param p 父元素
     * @param s 字符串
     */
    addSubElement(p, s, index, lastIndex) {
      var aIndex = s.indexOf("<a>", index);
      var bIndex = s.indexOf("<b>", index);
      var redIndex = s.indexOf("<red>", index);
      var next = 0;
      if (aIndex + bIndex + redIndex == -3) {
        var text = document.createElement("text");
        text.textContent = s.slice(index);
        p.appendChild(text);
        return;
      } else {
        var min = this.minArr([aIndex, bIndex, redIndex]);
        if (min != lastIndex && s.slice(index, min).length != 0) {
          var text = document.createElement("text");
          text.textContent = s.slice(index, min);
          p.appendChild(text);
        }
        if (min == aIndex) {
          var aEnd = s.indexOf("<a>", min + 1);
          next = aEnd + "<a>".length;
          var a = document.createElement("a");
          var content = s.slice(min + "<a>".length, aEnd);
          var menuStart = "<menu=".length;
          var menuEnd = content.indexOf(">");
          var menu = content.slice(menuStart, menuEnd);
          a.textContent = s.slice(min + menuEnd + "<a>>".length, aEnd);
          if (menu.length > 0) {
            a.setAttribute("menu", menu);
          }
          p.appendChild(a);
        } else if (min == bIndex) {
          var bEnd = s.indexOf("<b>", min + 1);
          next = bEnd + "<b>".length;
          var b = document.createElement("b");
          b.textContent = s.slice(min + "<b>".length, bEnd);
          p.appendChild(b);
        } else if (min == redIndex) {
          var redEnd = s.indexOf("<red>", min + 1);
          next = redEnd + "<red>".length;
          var red = document.createElement("text");
          red.textContent = s.slice(min + "<red>".length, redEnd);
          red.classList.add("fred");
          p.appendChild(red);
        }
        this.addSubElement(p, s, next, min);
      }
    },
    updateData(d) {
      var value = d;
      var container =
        document.getElementById("containerrow") ||
        document.getElementById("containercolumn");
      var policyNav = document.getElementById("policyNav");
      container.innerHTML = "";
      for (var i = 0; i < value.length; i++) {
        if (value[i].t == "text") {
          var p = document.createElement("p");
          this.addSubElement(p, value[i].i, 0, 0);
          var styleList = value[i].s.replace(/\s+/g, " ").split(" ");
          for (var j = 0; j < styleList.length; j++) {
            p.classList.add(styleList[j]);
          }
          container.appendChild(p);
        }
      }
      container.appendChild(policyNav)
      var alist = document.getElementsByTagName("a");
      for (var i = 0; i < alist.length; i++) {
        alist[i].addEventListener("click", (e) => {
          this.$data.showAnother = true;
          this.$data.header = e.target.innerHTML;
          var params = {
            i: "getContent",
            id: e.target.getAttribute("menu"),
          };
          new Http(true, this.$router).request(params).then((res) => {
            var container = document.getElementById("anotherContainer");
            container.innerHTML = "";
            for (var i = 0; i < res.data.length; i++) {
              if (4 == i) {
                if (res.data[i].t == "text") {
                  var p = document.createElement("p");
                  this.addSubElement(p, res.data[i].i, 0, 0);
                  var styleList = res.data[i].s.replace(/\s+/g, " ").split(" ");
                  for (var j = 0; j < styleList.length; j++) {
                    p.classList.add(styleList[j]);
                  }
                  container.appendChild(p);
                }
              }
            }
          });
        });
      }
    },
  },
  mounted() {},
};
</script>
<style>
a {
  color: rgb(9, 103, 224);
}
a:hover {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}
.fred {
  color: red;
}

.mytable {
  --el-table-border-color: #3b3b3b !important;
}

.blcok {
  width: 100%;
  padding: 15px 0;
  border-radius: 10px;
  background: #cecece;
  text-indent: 2em;
}

.indent2 {
  text-indent: 2em;
}

.indent4 {
  text-indent: 4em;
}

.top20 {
  margin-top: 20px;
}

.el-icon {
  font-size: 20px !important;
}

.nav span {
  display: block !important;
}
</style>
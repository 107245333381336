import { createRouter, createWebHashHistory } from 'vue-router'
import Learn from '../views/Learn.vue'
import Home from "../views/Home.vue"
import MenuManage from '@/views/MenuManage.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/learn/:articleType',
    name: 'learn',
    component: Learn
  },
  {
    path: '/menu_manage',
    name: 'menuManage',
    component: MenuManage
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

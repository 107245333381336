<template>
  <div style="padding: 20px">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item
        v-for="(item, index) in menu"
        :key="index"
        :title="item"
        :name="item"
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 200px;
            padding: 10px;
            height: 320px;
          "
        >
          <div class="card" style="flex: 1; font-size: 20px; text-align: center; padding: 10px; font-weight: 500;">
            你好你好你好你好你好你好你好你好你好你好你好你好你好你好你好你好
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 60px;
            "
          >
            <el-button @click="learn(item)">学习</el-button>
            <el-button @click="test(item)">测试</el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import Http from "@/Http";

export default {
  data() {
    return {
      menu: [],
    };
  },
  methods: {
    learn(t) {
      this.$router.push({ name: "learn", params: { articleType: t } });
    },
    test(t) {
      // this.$router.push({ name: "learn", params: { t: t } });
    },
  },
  mounted() {
    var params = {
      i: "getArticleTypes",
    };
    new Http(true, this.$router).request(params).then((res) => {
      var d = res.data;
      var a = [];
      for (var i = 0; i < d.length; i++) {
        a.push(d[i].articleType);
      }
      this.$data.menu = a;
    });
  },
};
</script>

<style>
.card {
  background: white;
  border-radius: 10px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(214, 214, 214),
    0.3em 0.3em 1em rgba(99, 99, 99, 0.3);
}
</style>
<template>
  <div style="display: flex; width: 100%">
    <div style="flex: 1">
      <el-menu
        :unique-opened="true"
        :collapse="isCollapse"
        @select="itemSelect"
        @open="subMenuOpen"
      >
        <SubMenu v-for="menu in menus" :key="menu.index" :menu="menu" />
      </el-menu>
    </div>
  </div>
</template>
 
<script>
import SubMenu from "./SubMenu.vue";
export default {
  name: "Menu",
  props: {
    menus: {
      type: Object,
      required: true,
    },
  },
  components: {
    SubMenu,
  },
  methods: {
    itemSelect(index, indexPath) {
      this.$emit("item-select-event", index, indexPath);
    },
    subMenuOpen(index, indexPath) {
      this.$emit("sub-menu-open-event", index, indexPath);
    },
  },
};
</script>
<template>
  <div style="padding: 20px">
    <div>
      <el-form>
        <el-form-item label="文章类型">
          <el-input v-model="articleType"></el-input>
        </el-form-item>
        <el-form-item label="文章来源">
          <el-input v-model="articleSource"></el-input>
        </el-form-item>
        <el-form-item label="文章标题">
          <el-input v-model="articleTitle"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="display: flex; width: 100%">
      <div style="flex: 1">
        <Menu
          style="width: 100%"
          :menus="menus"
          @item-select-event="menuItemSelect"
          @sub-menu-open-event="menuSubMenuOpen"
        ></Menu>
      </div>
      <div style="flex: 1; padding: 20px">
        <div
          style="
            line-height: 20px;
            padding: 20px;
            border-bottom: 2px solid #aaa;
          "
        >
          <span v-for="path in paths" :key="path"> {{ path + " / " }}</span>
        </div>
        <div
          v-for="item in items"
          :key="item"
          style="
            display: flex;
            padding: 10px 20px;
            border-bottom: 1px dashed #aaa;
          "
        >
          <div style="flex: 1; display: flex; align-items: center">
            {{ item }}
          </div>
          <div style="width: 100px">
            <el-button
              type="danger"
              @click="delectNode(item)"
              :disabled="items.length == 1"
              >删除</el-button
            >
          </div>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <el-button type="success" @click="addNode">添加</el-button>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        justify-content: center;
        border-top: 2px solid #aaa;
        padding-top: 20px;
      "
    >
      <el-button type="primary" @click="addMenu">确认</el-button>
    </div>
  </div>
</template>

<script>
import Http from "@/Http";
import Menu from "../components/Menu.vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components: {
    Menu,
  },
  data() {
    return {
      indexPath: [],
      paths: [],
      articleType: "",
      articleSource: "",
      articleTitle: "",
      items: [],
      menus: [
        {
          label: "导航",
          index: "导航",
          meta: {
            title: "导航",
          },
        },
      ],
    };
  },
  methods: {
    getItemByPath(menu, keyIndex) {
      for (var i = 0; i < menu.length; i++) {
        if (menu[i].index == this.$data.indexPath[keyIndex]) {
          if (keyIndex == this.$data.indexPath.length - 1) {
            return menu[i];
          }
          return this.getItemByPath(menu[i].children, keyIndex + 1);
          break;
        }
      }
    },

    menuItemSelect(index, indexPath) {
      this.$data.indexPath = indexPath;
      this.$data.paths = [];
      this.indexToPath(this.$data.menus, 0);
      this.$data.items = [];
    },
    menuSubMenuOpen(index, indexPath) {
      this.$data.indexPath = indexPath;
      this.$data.paths = [];
      this.indexToPath(this.$data.menus, 0);
      var submenus = this.getItemByPath(this.$data.menus, 0);
      var arr = [];
      if (!submenus.children) {
        this.$data.items = [];
        return;
      }
      for (var i = 0; i < submenus.children.length; i++) {
        arr.push(submenus.children[i].label);
      }
      this.$data.items = arr;
    },

    deleteNodeLayer(menu, keyIndex, deleteLabel) {
      for (var i = 0; i < menu.length; i++) {
        if (menu[i].index == this.$data.indexPath[keyIndex]) {
          if (keyIndex == this.$data.indexPath.length - 1) {
            for (var j = 0; j < menu[i].children.length; j++) {
              if (menu[i].children[j].label == deleteLabel) {
                menu[i].children.splice(j, 1);
                if (menu[i].children.length == 0) {
                  delete menu[i].children;
                }
              }
            }
          }
          this.deleteNodeLayer(menu[i].children, keyIndex + 1, deleteLabel);
          break;
        }
      }
    },

    indexToPath(menu, keyIndex) {
      for (var i = 0; i < menu.length; i++) {
        if (menu[i].index == this.$data.indexPath[keyIndex]) {
          this.$data.paths.push(menu[i].meta.title);
          if (menu[i].children) {
            this.indexToPath(menu[i].children, keyIndex + 1);
          }
          break;
        }
      }
    },

    delectNode(n) {
      this.deleteNodeLayer(this.$data.menus, 0, n);
      for (var i = 0; i < this.$data.items.length; i++) {
        if (this.$data.items[i] == n) {
          this.$data.items.splice(i, 1);
        }
      }
    },

    addNodeLayer(menu, keyIndex, o) {
      for (var i = 0; i < menu.length; i++) {
        if (menu[i].index == this.$data.indexPath[keyIndex]) {
          if (keyIndex == this.$data.indexPath.length - 1) {
            if (!menu[i].children) {
              menu[i].children = [o];
            } else {
              menu[i].children.push(o);
            }
          }
          this.addNodeLayer(menu[i].children, keyIndex + 1, o);
          break;
        }
      }
    },

    addNode() {
      ElMessageBox.prompt("请输入节点名称", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (JSON.stringify(this.$data.menus).indexOf(value) != -1) {
            this.$alert("节点名称重复", { type: "error" });
            return;
          }
          if (!value || value.length == 0) {
            this.$alert("请输入节点名称", { type: "error" });
          } else if (this.$data.indexPath.length == 0) {
            this.$alert("请选择父节点", { type: "error" });
          } else {
            this.$data.items.push(value);
            var o = {
              label: value,
              index: (Math.random() * 1e10 + "")
                .substring(0, 10)
                .padEnd(10, "0"),
              meta: {
                title: value,
              },
            };
            this.addNodeLayer(this.$data.menus, 0, o);
            console.error(this.$data.menus);
          }
        })
        .catch(() => {});
    },
    addMenu() {
      if (this.$data.articleType == 0) {
        this.$alert("请输入文章类型", { type: "error" });
        return;
      }
      if (this.$data.articleSource == 0) {
        this.$alert("请输入文章来源", { type: "error" });
        return;
      }
      if (this.$data.articleTitle == 0) {
        this.$alert("请输入文章标题", { type: "error" });
        return;
      }
      var params = {
        articleType: this.$data.articleType,
        articleSource: this.$data.articleSource,
        articleTitle: this.$data.articleTitle,
        i: "addMenu",
        menu: this.$data.menus,
      };
      new Http(true, this.$router).request(params).then((res) => {
        this.$alert("添加成功", { type: "success" });
      });
    },
  },
};
</script>

<style>
</style>
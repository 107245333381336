<template>
  <el-sub-menu v-if="menu.children && menu.children.length>0" :index="menu.index">
    <template #title>{{ menu.meta.title }}</template>
    <SubMenu v-for="menu1 in menu.children" :key="menu1.index" :menu="menu1" />
  </el-sub-menu>
  <el-menu-item v-else :index="menu.index">{{ menu.meta.title }} </el-menu-item>
</template>

<script>
import SubMenu from "./SubMenu.vue";
export default {
  name: "SubMenu",
  components: {
    SubMenu,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>